import React from 'react';
import { Container, Navbar, Nav, Button, Row, Col, Card, Form, Alert } from 'react-bootstrap';
import { registerInterest } from './api/RegisterCreator';

const PRIMARY_COLOR = '#026440';
const APP_STORE_URL = 'https://apps.apple.com/us/app/lookaround-live/id6670697196';

const styles = `
  .navbar-custom {
    background-color: ${PRIMARY_COLOR};
  }

  .hero-section {
    background-color: ${PRIMARY_COLOR};
    color: white;
    padding: 50px 0;
  }

  .benefit-card {
    height: 100%;
    border-left: 4px solid ${PRIMARY_COLOR};
    transition: transform 0.2s;
  }

  .benefit-card:hover {
    transform: translateY(-5px);
  }

  .process-card {
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .number-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${PRIMARY_COLOR};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .signup-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .btn-custom {
    background-color: ${PRIMARY_COLOR};
    border-color: ${PRIMARY_COLOR};
    color: white;
  }

  .btn-custom:hover {
    background-color: #015535;
    border-color: #015535;
    color: white;
  }
`;

export const CreatorSignupPage = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    expertise: '',
  });
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    // Handle form submission here
    registerInterest(formData);
    setShowSuccess(true);
    setFormData({
      name: '',
      email: '',
      expertise: '',
    });
    setTimeout(() => setShowSuccess(false), 5000);
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <style>{styles}</style>

      {/* Navigation */}
      <Navbar expand='lg' className='navbar-custom navbar-dark'>
        <Container>
          <Navbar.Brand href='/'>LookAround</Navbar.Brand>
          <Navbar.Toggle aria-controls='creator-nav' />
          <Navbar.Collapse id='creator-nav'>
            <Nav className='me-auto'>
              <Nav.Link href='/'>Home</Nav.Link>
            </Nav>
            <Button variant='light' href={APP_STORE_URL} target='_blank' rel='noopener noreferrer'>
              Download App
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className='hero-section'>
        <Container>
          <Row className='align-items-center'>
            <Col lg={6}>
              <h1 className='display-4 fw-bold mb-4'>Sign up as a Content Creator</h1>
              <p className='lead mb-4'>
                Join our community of storytellers and guides. Share your unique perspective of your neighborhood help
                users see things from a different lens.
              </p>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  maxWidth: '450px',
                  margin: '0 auto', // Center the phone
                  borderRadius: '12px',
                  overflow: 'hidden',
                  backgroundColor: '#333',
                }}
              >
                <img
                  src='https://lookaround-live.s3.amazonaws.com/1a0fe4163b1e4807a7e4e985c1d405fa_ContentCreatorScreenshots.jpg'
                  alt='LookAround App Interface'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'top center',
                  }}
                  className='img-fluid rounded-3 shadow'
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Benefits Section */}
      <section className='py-5' id='benefits'>
        <Container>
          <h2 className='text-center mb-5'>Why Become a Creator?</h2>
          <Row>
            {[
              {
                title: 'Build a spatial storyboard',
                description:
                  'Help people discover stories as they move around. Tell diverse stories and connect people to them in the right context.',
              },
              {
                title: 'Reach a broader audience',
                description:
                  "Already a guide? Don't be caught up with scheduling tours. LookAround serves tours 24/7 so you can focus on developing great content to increase revenue.",
              },
            ].map((benefit, index) => (
              <Col md={6} className='mb-4' key={index}>
                <Card className='benefit-card h-100'>
                  <Card.Body>
                    <Card.Title className='h4 mb-3'>{benefit.title}</Card.Title>
                    <Card.Text>{benefit.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Process Section */}
      <section className='bg-light py-5' id='process'>
        <Container>
          <h2 className='text-center mb-5'>How It Works</h2>
          <Row>
            {[
              {
                step: 1,
                title: 'Sign Up',
                description: 'Fill out the form below and tell us about your interests.',
              },
              {
                step: 2,
                title: 'Get Approved',
                description: 'Our team will review your interests and provide access to the creator tools.',
              },
              {
                step: 3,
                title: 'Create Content',
                description:
                  'Start creating location-based stories, guides, and experiences for the LookAround community.',
              },
              {
                step: 4,
                title: 'Share & Earn',
                description:
                  'Get paid as users access your content and build your reputation as an expert storyteller.',
              },
            ].map((process, index) => (
              <Col md={3} className='mb-4' key={index}>
                <Card className='process-card text-center h-100'>
                  <Card.Body>
                    <div className='number-circle mx-auto'>{process.step}</div>
                    <Card.Title>{process.title}</Card.Title>
                    <Card.Text>{process.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Application Form */}
      <section className='py-5'>
        <Container>
          <h2 className='text-center mb-5'>Ready to Get Started?</h2>
          {showSuccess && (
            <Alert variant='success' className='mb-4 text-center'>
              Thank you for your interest! We'll review your submission and reach out soon.
            </Alert>
          )}
          <Form className='signup-form' onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>Full Name</Form.Label>
              <Form.Control type='text' name='name' value={formData.name} onChange={handleChange} required />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type='email' name='email' value={formData.email} onChange={handleChange} required />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>What type of content would you like to create</Form.Label>
              <Form.Control
                as='textarea'
                name='expertise'
                value={formData.expertise}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>

            <div className='text-center'>
              <Button type='submit' className='btn-custom btn-lg'>
                Register as creator
              </Button>
            </div>
          </Form>
        </Container>
      </section>

      {/* Footer */}
      <Container
        fluid
        className='py-3'
        style={{
          backgroundColor: PRIMARY_COLOR,
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Row className='px-4'>
          <Col className='d-flex justify-content-between align-items-center flex-wrap'>
            <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>© 2024 LookAround Inc.</span>
            <div className='d-flex align-items-center footer-links'>
              <a
                href='/terms_of_service'
                className='text-decoration-none mx-3'
                style={{ color: 'rgba(255, 255, 255, 0.7)' }}
              >
                Terms of service
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
