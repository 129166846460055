import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NoPage } from './component/static_pages/NoPage';
import { About } from './component/static_pages/About';
import { Homepage } from './component/signin/Homepage';
import { TermsOfService } from './component/static_pages/TermsOfService';
import { EnableLocation } from './component/static_pages/EnableLocation';
import { CreatorsPage } from './component/v2/CreatorsPage/CreatorsPage';

// Component to handle the redirect
const RedirectToFile = ({ filename }) => {
  useEffect(() => {
    const path = `${process.env.PUBLIC_URL}/files/${filename}`;
    window.location.href = path;
  }, [filename]);

  return null; // This component does not render anything
};

function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty array ensures this effect runs only once

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Homepage />} />
        <Route path='/about' element={<About />} />
        <Route path='/terms_of_service' element={<TermsOfService />} />
        <Route path='/enable_location' element={<EnableLocation />} />
        <Route path='/map' element={<Homepage startingPage='mapTool' />} />
        <Route path='/creators' element={<CreatorsPage />} />
        <Route
          path='/.well-known/apple-developer-merchantid-domain-association'
          element={<RedirectToFile filename='apple-developer-merchantid-domain-association' />}
        />
        <Route path='/*' element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
