import React from 'react';
import { Container, Navbar, Nav, Button, Row, Col, Card } from 'react-bootstrap';

const PRIMARY_COLOR = '#026440';
const APP_STORE_URL = 'https://apps.apple.com/us/app/lookaround-live/id6670697196';

// Custom styles to be added to LandingPage.css
const styles = `
.navbar-custom {
  background-color: ${PRIMARY_COLOR};
}

.hero-section {
  background-color: ${PRIMARY_COLOR};
  color: white;
  padding: 50px 0;
}

.feature-card {
  height: 100%;
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.testimonial-section {
  background-color: ${PRIMARY_COLOR};
  color: white;
  padding: 80px 0;
}

.cta-section {
  padding: 80px 0;
}

.btn-custom {
  background-color: ${PRIMARY_COLOR};
  border-color: ${PRIMARY_COLOR};
}

.btn-custom:hover {
  background-color: #015535;
  border-color: #015535;
}

.btn-custom-light {
  background-color: white;
  border-color: white;
  color: ${PRIMARY_COLOR};
}

.btn-custom-light:hover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: ${PRIMARY_COLOR};
}

.footer-links a:hover {
    color: white !important;
    transition: color 0.2s ease;
  }
  
  @media (max-width: 768px) {
    .footer-links {
      margin-top: 0.5rem;
      width: 100%;
      justify-content: flex-start;
    }
    
    .footer-links a:first-child {
      margin-left: 0;
    }
  }

  .app-store-button {
    /* For focus states - accessibility */
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
      border-radius: 6px;
    }
    
    /* For touch devices */
    @media (hover: none) {
      &:active {
        transform: scale(0.95);
        opacity: 0.9;
      }
    }
  }
`;

// Header Component
const Header = () => (
  <Navbar expand='lg' className='navbar-custom navbar-dark'>
    <Container>
      <Navbar.Brand href='#home'>LookAround</Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='me-auto'>
          <Nav.Link href='/creators'>Creators</Nav.Link>
        </Nav>
        <Button variant='light' href={APP_STORE_URL} target='_blank' rel='noopener noreferrer'>
          Download App
        </Button>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

// Hero Section Component
const Hero = () => (
  <section className='hero-section'>
    <Container>
      <Row className='align-items-center'>
        <Col md={6} className='mb-4 mb-md-0'>
          <h1 className='display-4 fw-bold mb-4'>Storytelling marketplace for your neighborhood</h1>
          <p className='lead mb-4'>
            Discover stories that make your neighborhood special. Develop a new perspective and become the go-to local
            storyteller.
          </p>
        </Col>
        <Col md={6}>
          <div
            style={{
              maxWidth: '325px',
              margin: '0 auto', // Center the phone
              borderRadius: '32px',
              overflow: 'hidden',
              backgroundColor: '#333',
            }}
          >
            <img
              src='https://lookaround-live.s3.amazonaws.com/c074c01056324bf7932daf999c352411_AppScreenshots.jpg'
              alt='LookAround App Interface'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'top center',
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mb-1 mb-2 md-0 mt-5 d-flex justify-content-center'>
          <a
            href={APP_STORE_URL}
            target='_blank'
            rel='noopener noreferrer'
            className='app-store-button'
            style={{
              display: 'inline-block',
              width: '156px',
              transition: 'transform 0.2s ease, opacity 0.2s ease',
              cursor: 'pointer',
            }}
          >
            <img
              src='https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1725840000'
              alt='Download on the App Store'
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            />
          </a>
        </Col>
      </Row>
    </Container>
  </section>
);

// About Section Component
const About = () => (
  <section className='py-5' id='about'>
    <Container>
      <h2 className='text-center mb-5'>Every Walk can be a Memorable Experience</h2>
      <Row>
        {[
          {
            title: 'Explore',
            description: 'Discover hidden spots, untold stories and access guides about a broad range of topics.',
          },
          {
            title: 'Learn',
            description: 'Play location specific trivia and dive deeper into topics you find interesting.',
          },
          {
            title: 'Share and monetize',
            description: 'Become the local storyteller/ guide. Share your perspective and earn money and cred.',
          },
        ].map((feature, index) => (
          <Col md={4} className='mb-4' key={index}>
            <Card className='feature-card h-100'>
              <Card.Body>
                <Card.Title className='h4 mb-3'>{feature.title}</Card.Title>
                <Card.Text>{feature.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

// Testimonials Section Component
const Testimonials = () => (
  <section className='testimonial-section' id='testimonials'>
    <Container>
      <h2 className='text-center mb-5'>What Our Users Say</h2>
      <Row>
        <Col md={4} className='mb-4' key='1'>
          <Card className='h-100'>
            <Card.Body>
              <Card.Text>
                "LookAround helped me discover amazing places in SF that I never knew existed, even after living here
                for years!"
              </Card.Text>
              <div className='d-flex align-items-center'>
                {/* <img
                        src="/api/placeholder/40/40"
                        alt="User"
                        className="rounded-circle me-3"
                    /> */}
                <div>
                  <p className='mb-0 fw-bold'>Evan G.</p>
                  <small className='text-muted'>Telegraph Hill Resident</small>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className='mb-4' key='2'>
          <Card className='h-100'>
            <Card.Body>
              <Card.Text>
                "I tried LookAround for its content and ended up re-discovering my passion for storytelling. The team is
                very proactive and is building something truly meaningful."
              </Card.Text>
              <div className='d-flex align-items-center'>
                <div>
                  <p className='mb-0 fw-bold'>Sid G.</p>
                  <small className='text-muted'>Content creator</small>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className='mb-4' key='3'>
          <Card className='h-100'>
            <Card.Body>
              <Card.Text>
                "It was amazing to learn the origins of Nob Hill and the stories of the businesses. It felt like being
                part of the movie of SF. Thank you!"
              </Card.Text>
              <div className='d-flex align-items-center'>
                <div>
                  <p className='mb-0 fw-bold'>Lindsay W.</p>
                  <small className='text-muted'>Tourist</small>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
);

// CTA Section Component
const CTA = () => (
  <section className='cta-section bg-light'>
    <Container className='text-center'>
      <h2 className='mb-4'>Start Exploring Today</h2>
      <p className='lead mb-4'>
        Download LookAround and start discovering San Francisco's hidden treasures.
        <br />
        <a
          href='/creators'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: PRIMARY_COLOR,
            textDecoration: 'none',
            borderBottom: `2px solid ${PRIMARY_COLOR}`,
            fontWeight: 500,
            transition: 'opacity 0.2s ease',
          }}
          onMouseEnter={e => (e.target.style.opacity = '0.7')}
          onMouseLeave={e => (e.target.style.opacity = '1')}
        >
          Become a creator
        </a>{' '}
        and work with us to start monetizing your content.
      </p>

      <a
        href={APP_STORE_URL}
        style={{
          display: 'inline-block',
          width: '200px',
          transition: 'transform 0.2s ease, opacity 0.2s ease',
        }}
      >
        <img
          src='https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us'
          alt='Download on the App Store'
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
          }}
        />
      </a>
    </Container>
  </section>
);

// Footer Component
const Footer = () => (
  <Container
    fluid
    className='py-3'
    style={{
      backgroundColor: '#026440',
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    }}
  >
    <Row className='px-4'>
      <Col className='d-flex justify-content-between align-items-center flex-wrap'>
        <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>© 2024 LookAround Inc.</span>

        <div className='d-flex align-items-center footer-links'>
          <a
            href='/terms_of_service'
            className='text-decoration-none mx-3'
            style={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            Terms of service
          </a>
        </div>
      </Col>
    </Row>
  </Container>
);

export const SignedOutLandingPage = () => (
  <>
    <style>{styles}</style>
    <meta name='apple-itunes-app' content='app-id=6670697196'></meta>
    <Header />
    <main>
      <Hero />
      <About />
      <Testimonials />
      <CTA />
    </main>
    <Footer />
  </>
);
