import React, { useEffect, useState, useCallback } from 'react';
import { LandingPage } from '../LandingPage';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { ChannelList } from '../channel_list/ChannelList';
import { SignedInLandingPage } from './views/SignedInLandingPage';
import { PricingPage } from './subscription/PricingPage';
import { LookAroundNavbar } from '../navbar/LookAroundNavbar';
import { SignedInNavbar } from '../navbar/SignedInNavbar';
import { signInWithGoogle, signInWithToken, signout } from '../../api/authentication_api';
import './Homepage.css';
import { Header } from '../header/Header';
import { MapPage } from '../../tools/MapPage';
import { ExperienceDetails } from '../experiences/experience_details/ExperienceDetails';
import { CreateExperienceForm } from '../experiences/create/CreateExperienceForm';
import { LookAroundPage } from '../experiences/lookaround_page/LookAroundPage';
import { NavigateToExperiencePage } from '../experiences/NavigateToExperiencePage';
import { postLocationAccessDenied } from '../../api/channel_api';
import { SignedOutLandingPage } from '../v2/SignedOutLandingPage/SignedOutLandingPage';

export const Homepage = ({ startingPage = 'landing' }) => {
  const [signInState, setSignInState] = useState({
    status: startingPage === 'mapTool' ? 'signedOut' : 'defaultLanding',
  });
  const [signedInView, setSignedInView] = useState(startingPage);
  const [lookAroundChannelList, setLookAroundChannelList] = useState({});
  const [timelineChannelList, setTimelineChannelList] = useState([]);
  const [channelDetails, setChannelDetails] = useState({});
  const [previousView, setPreviousView] = useState(startingPage);
  const [channelNavigationDetails, setChannelNavigationDetails] = useState({});

  const handleUserEvent = ({ eventType, payload }) => {
    if (eventType === 'error_location') {
      postLocationAccessDenied();
    } else {
      console.log(`Unrecognized event: ${eventType}`);
    }
  };

  const homepageCallback = ({ callbackType, payload }) => {
    if (callbackType !== signedInView && callbackType !== 'loading') {
      if (signedInView !== 'navigateToChannel') {
        setPreviousView(signedInView);
      }
    }
    setSignedInView(callbackType);
    if (callbackType === 'lookaroundPublic') {
      setLookAroundChannelList(payload);
      setSignInState({ status: 'signedOut' });
    } else if (callbackType === 'channelDetails') {
      setChannelDetails(payload);
    } else if (callbackType === 'timeline') {
      setTimelineChannelList(payload.timelineChannels);
    } else if (callbackType === 'lookaround') {
      setLookAroundChannelList(payload);
    } else if (callbackType === 'signout') {
      handleSignOut();
    } else if (callbackType === 'navigateToChannel') {
      setChannelNavigationDetails(payload);
    } else if (callbackType === 'channelDetailsAndUpdatedList') {
      // setLookAroundChannelList()
      setLookAroundChannelList(payload.lookaround);
      setSignedInView('lookaround');
      setPreviousView('lookaround');
      homepageCallback({
        callbackType: 'channelDetails',
        payload: payload.channelDetails,
      });
    } else if (callbackType === 'mapTool') {
      // nothing needed yet. Check for user type here.
    } else if (callbackType === 'createExperience') {
      // nothing needed yet. Check for user type here.
    } else if (callbackType === 'loading') {
      // nothing to do here
    } else if (callbackType === 'subscribe') {
      // nothing to do here
    } else if (callbackType === 'landing') {
      // nothing to do here
    } else {
      throw new Error(`Unrecognized callbackType, ${callbackType}`);
    }
  };

  // Using "credential" is important as that is the key in Google's response.
  const verifyToken = useCallback(
    ({ credential }) => {
      const onSignInSuccess = data => {
        setSignInState({
          status: 'signedIn',
          username: data.givenName,
          isSubscribed: data.isSubscribed,
          isCreator: data.isCreator,
          experienceCount: data.experienceCount,
          totalExperienceCount: data.totalExperienceCount,
        });
        if (data.isCreator) {
          setSignedInView(startingPage);
        } else {
          setSignedInView('landing');
        }
      };
      if (credential) {
        setSignInState({ status: 'signingIn' });
        signInWithGoogle(credential).then(response => {
          if (response.statusCode === 200) {
            localStorage.setItem('access_token', response.access_token);
            onSignInSuccess({
              givenName: response.given_name,
              isSubscribed: response.is_subscribed,
              isCreator: response.is_creator,
              experienceCount: response.experience_count,
              totalExperienceCount: response.total_experience_count,
            });
          } else {
            onSignInFailure('Unable to sign in. Please try again.');
          }
        });
      } else {
        onSignInFailure('could not retrieve credentials');
      }
    },
    [startingPage]
  );

  const signInUsingAccessToken = useCallback(() => {
    const onSignInSuccess = data => {
      setSignInState({
        status: 'signedIn',
        username: data.givenName,
        isSubscribed: data.isSubscribed,
        isCreator: data.isCreator,
        experienceCount: data.experienceCount,
        totalExperienceCount: data.totalExperienceCount,
      });
      if (data.isCreator) {
        setSignedInView(startingPage);
      } else {
        setSignedInView('landing');
      }
    };
    signInWithToken().then(response => {
      if (response.statusCode === 200) {
        localStorage.setItem('access_token', response.access_token);
        onSignInSuccess({
          givenName: response.given_name,
          isSubscribed: response.is_subscribed,
          isCreator: response.is_creator,
          experienceCount: response.experience_count,
          totalExperienceCount: response.total_experience_count,
        });
      } else {
        onSignInFailure('Unable to sign in. Please try again.');
      }
    });
  }, [startingPage]);

  const onSignInFailure = error => {
    localStorage.removeItem('access_token');
    setSignInState({ status: 'error', message: error });
  };

  useEffect(() => {
    // TODO: remove this line after 10/31/2024
    localStorage.removeItem('jwtToken');
    // If a token is already set in the browser, attempt to authenticate the user.
    const storedToken = localStorage.getItem('access_token');
    if (storedToken) {
      setSignInState({ status: 'signingIn' });
      signInUsingAccessToken();
    }
  }, [verifyToken, signInUsingAccessToken]);

  const handleSignOut = async event => {
    setSignInState({ status: 'signingOut' });
    localStorage.removeItem('access_token');
    signout().then(response => {
      if (response.statusCode !== 200) {
        console.log('Something went wrong but you have been signed out.');
      }
      setSignInState({ status: 'defaultLanding' });
    });
  };

  if (signInState.status === 'signedIn' && signedInView === 'createExperience') {
    return (
      <div>
        <SignedInNavbar homepageCallback={homepageCallback} />
        <ResponsiveBox
          isSnug={true}
          children={[
            {
              leftChild: (
                <Header
                  title={'Create Experience'}
                  onClickFunction={() => homepageCallback({ callbackType: 'mapTool' })}
                />
              ),
            },
          ]}
        />
        <CreateExperienceForm homepageCallback={homepageCallback} isSubscribed={signInState.isSubscribed} />
      </div>
    );
  }

  return (
    <div>
      {(startingPage !== 'mapTool' || signInState.status === 'defaultLanding' || signInState.status === 'error') && (
        <SignedOutLandingPage />
      )}
      {(signInState.status === 'signedOut' || signInState.status === 'error') && (
        <div>
          <LookAroundNavbar showBrand={false} />
          {signInState.status === 'error' && (
            <ResponsiveBox
              isSnug={true}
              children={[
                {
                  leftChild: (
                    <p style={{ fontSize: '14px', color: 'maroon' }}>You have been logged out, please sign in again.</p>
                  ),
                },
              ]}
            />
          )}
          <LandingPage
            verifyToken={verifyToken}
            onSignInFailure={onSignInFailure}
            homepageCallback={homepageCallback}
            handleUserEvent={handleUserEvent}
          />
        </div>
      )}
      {/* FIXME- udpate this status to just be signedOut and use the view to identify lookaround */}
      {signInState.status === 'signedOutLookAround' && (
        <LookAroundPage
          homepageCallback={homepageCallback}
          lookAroundChannelList={lookAroundChannelList}
          verifyToken={verifyToken}
          onSignInFailure={onSignInFailure}
          signInStatus={'signedOut'}
        />
      )}
      {/* Sign in loading */}
      {signInState.status === 'signingIn' && (
        <div>
          <LookAroundNavbar />
          <ResponsiveBox
            isSnug={true}
            children={[{ leftChild: <p style={{ textAlign: 'center' }}>Signing in...</p> }]}
          />
        </div>
      )}
      {signInState.status === 'signingOut' && (
        <div>
          <LookAroundNavbar />
          <ResponsiveBox
            isSnug={true}
            children={[{ leftChild: <p style={{ textAlign: 'center' }}>Signing out...</p> }]}
          />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'loading' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <ResponsiveBox isSnug={true} children={[{ leftChild: <p style={{ textAlign: 'center' }}>Loading...</p> }]} />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'navigateToChannel' && (
        <NavigateToExperiencePage
          homepageCallback={homepageCallback}
          channelNavigationDetails={channelNavigationDetails}
          lookAroundChannelList={lookAroundChannelList}
        />
      )}
      {signInState.status === 'signedIn' && signedInView === 'landing' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <SignedInLandingPage
            homepageCallback={homepageCallback}
            username={signInState.username}
            experienceCount={signInState.experienceCount}
            handleUserEvent={handleUserEvent}
          />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'lookaround' && (
        <LookAroundPage
          homepageCallback={homepageCallback}
          signInStatus={signInState.status}
          lookAroundChannelList={lookAroundChannelList}
          verifyToken={verifyToken}
          onSignInFailure={onSignInFailure}
          userExperienceCount={signInState.experienceCount}
          username={signInState.username}
          totalExperienceCount={signInState.totalExperienceCount}
        />
      )}
      {signInState.status === 'signedIn' && signedInView === 'timeline' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <ResponsiveBox
            isSnug={true}
            children={[
              {
                leftChild: (
                  <Header title={'Timeline'} onClickFunction={() => homepageCallback({ callbackType: 'landing' })} />
                ),
              },
            ]}
          />
          <ResponsiveBox
            isSnug={true}
            children={[
              {
                leftChild: <div style={{ marginLeft: '5px', fontWeight: 'bold' }}>Experiences completed by you</div>,
              },
            ]}
          />
          <ChannelList channels={timelineChannelList} homepageCallback={homepageCallback} />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'channelDetails' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <ExperienceDetails
            channelDetails={channelDetails}
            homepageCallback={homepageCallback}
            backButtonFunction={() => setSignedInView(previousView)}
          />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'subscribe' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <ResponsiveBox isSnug={true} children={[{ leftChild: <PricingPage /> }]} />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'mapTool' && (
        <MapPage homepageCallback={homepageCallback} signInState={signInState} />
      )}
    </div>
  );
};
