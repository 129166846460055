import React, { useState } from 'react';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { LookAroundNavbar } from '../../navbar/LookAroundNavbar';
import { SignedInNavbar } from '../../navbar/SignedInNavbar';
import { signout } from '../../../api/authentication_api';
import { Header } from '../../header/Header';
import { CreatorSignupPage } from './CreatorsSignupPage';

export const CreatorsPage = ({ startingPage = 'landing' }) => {
  const [signInState, setSignInState] = useState({
    status: 'signedOut',
  });
  const [signedInView, setSignedInView] = useState(startingPage);

  const homepageCallback = ({ callbackType, payload }) => {
    setSignedInView(callbackType);
    if (callbackType === 'signout') {
      handleSignOut();
    } else if (callbackType === 'createExperience') {
      // nothing needed yet. Check for user type here.
    } else if (callbackType === 'loading') {
      // nothing to do here
    } else if (callbackType === 'landing') {
      // nothing to do here
    } else {
      throw new Error(`Unrecognized callbackType, ${callbackType}`);
    }
  };

  const handleSignOut = async event => {
    setSignInState({ status: 'signingOut' });
    localStorage.removeItem('access_token');
    signout().then(response => {
      if (response.statusCode !== 200) {
        console.log('Something went wrong but you have been signed out.');
      }
      setSignInState({ status: 'signedOut' });
    });
  };

  if (signInState.status === 'signedIn' && signedInView === 'createExperience') {
    return (
      <div>
        <SignedInNavbar homepageCallback={homepageCallback} />
        <ResponsiveBox
          isSnug={true}
          children={[
            {
              leftChild: (
                <Header
                  title={'Create Experience'}
                  onClickFunction={() => homepageCallback({ callbackType: 'mapTool' })}
                />
              ),
            },
          ]}
        />
      </div>
    );
  }

  return (
    <div>
      {(signInState.status === 'signedOut' || signInState.status === 'error') && (
        <div>
          {signInState.status === 'error' && (
            <ResponsiveBox
              isSnug={true}
              children={[
                {
                  leftChild: (
                    <p style={{ fontSize: '14px', color: 'maroon', textAlign: 'center' }}>
                      You have been logged out, please sign in again.
                    </p>
                  ),
                },
              ]}
            />
          )}
          <CreatorSignupPage />
        </div>
      )}
      {/* Sign in loading */}
      {signInState.status === 'signingIn' && (
        <div>
          <LookAroundNavbar />
          <ResponsiveBox
            isSnug={true}
            children={[{ leftChild: <p style={{ textAlign: 'center' }}>Signing in...</p> }]}
          />
        </div>
      )}
      {signInState.status === 'signingOut' && (
        <div>
          <LookAroundNavbar />
          <ResponsiveBox
            isSnug={true}
            children={[{ leftChild: <p style={{ textAlign: 'center' }}>Signing out...</p> }]}
          />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'loading' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <ResponsiveBox isSnug={true} children={[{ leftChild: <p style={{ textAlign: 'center' }}>Loading...</p> }]} />
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'landing' && (
        <div>
          <SignedInNavbar homepageCallback={homepageCallback} />
          <h1>SignedInLandingPage</h1>
          {/* <SignedInLandingPage
            homepageCallback={homepageCallback}
            username={signInState.username}
            experienceCount={signInState.experienceCount}
            handleUserEvent={handleUserEvent}
          /> */}
        </div>
      )}
      {signInState.status === 'signedIn' && signedInView === 'mapTool' && <h1>SignedInView = mapTool</h1>}
    </div>
  );
};
