import React from 'react';
import { ResponsiveBox } from './responsive_box/ResponsiveBox';
import { CyclingText } from './library/CyclingText';
import { GoogleLogin } from '@react-oauth/google';

export const LandingPage = ({ verifyToken, onSignInFailure, homepageCallback, handleUserEvent }) => {
  return (
    <div>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta property='og:title' content='LookAround' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.lookaround.live/' />
      <meta
        property='og:description'
        content='Created by Siddharth Gupta, learn more at https://www.lookaround.live/about'
      />
      <ResponsiveBox
        children={[
          {
            leftChild: (
              <div>
                <h1 style={{ textAlign: 'center', fontSize: '2.25rem' }}>
                  LookAround <br />
                  <span
                    style={{
                      color: '#00897B',
                      textDecoration: 'underline',
                      textDecorationColor: '#1E272C',
                      fontFamily: 'Pacifico',
                    }}
                  >
                    &nbsp;
                    <CyclingText
                      options={['North Beach', 'Telegraph Hill', 'Chinatown', 'Nob Hill', 'Russian Hill']}
                      interval={2300}
                    />
                    &nbsp;
                  </span>{' '}
                  like a <br />
                  <span
                    style={{
                      color: '#00897B',
                      textDecoration: 'underline',
                      textDecorationColor: '#1E272C',
                      fontFamily: 'Pacifico',
                    }}
                  >
                    &nbsp;
                    <CyclingText
                      options={['Beat poet', 'bird watcher', 'shopkeeper', 'chef', 'philanthropist']}
                      interval={2300}
                    />
                    &nbsp;
                  </span>
                </h1>
              </div>
            ),
          },
          {
            leftChild: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src='https://lookaround-live.s3.us-west-1.amazonaws.com/static/Lookaround_homepage.jpg'
                  alt='LookaroundIllustration'
                  style={{
                    maxWidth: '60%',
                    marginTop: '20px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </div>
            ),
            rightChild: <div></div>,
            leftWidth: '100%',
          },
          {
            leftChild: <div></div>,
            rightChild: (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginLeft: '90px' }}>
                <GoogleLogin onSuccess={verifyToken} onError={onSignInFailure} text='continue_with' />
              </div>
            ),
          },
          {
            leftChild: (
              <div style={{ marginTop: '20px' }}>
                <h2 style={{ textAlign: 'center', fontSize: '1.75rem' }}>
                  Explore local{' '}
                  <span
                    style={{
                      color: '#00897B',
                      textDecoration: 'underline',
                      textDecorationColor: '#1E272C',
                      fontFamily: 'Pacifico',
                    }}
                  >
                    &nbsp;
                    <CyclingText options={['history', 'culture', 'art', 'cuisine', 'stories']} interval={5000} />
                    &nbsp;
                  </span>
                  <br />
                  when you want, <br />
                  where you want.
                </h2>
              </div>
            ),
          },
        ]}
        isSnug={true}
      />
    </div>
  );
};
